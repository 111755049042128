import { Controller } from "stimulus"
import baguetteBox from 'baguettebox.js'
export default class extends Controller {
  connect() {
    this.gallery = baguetteBox.run(`#${this.element.id}`)
  }
  disconnect() {
    if (this.gallery) {
      this.gallery.hide()
      this.gallery.destroy()
    }
  }
}
