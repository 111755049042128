import { Controller } from "stimulus"
import $ from "jquery";
import Turbolinks from "turbolinks"

export default class extends Controller {
  static targets = [ "clock" ]

  connect() {
    const end = this.data.has('endTime') && new Date(this.data.get('endTime'));

    if (!end) return;

    const end_time = end.getTime();
    let cnt = 0;
    this._timer = setInterval(() => {
      this.update(end_time, cnt);
      cnt += 1000;
    }, 1000);
    this.update(end_time, cnt);
    cnt += 1000;
  }

  disconnect() {
    this.stopTimer();
  }

  update(end_time, diff) {
    let now = new Date(this.data.get('currentTime')).getTime() + diff;
    let distance = end_time - now
    let loadedUrl = window.location.href;
    if (this.data.has('loadedUrl')) {
      loadedUrl = this.data.get('loadedUrl')
    }

    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (distance < 0) {
      this.stopTimer();
      return Turbolinks.visit(loadedUrl, {replace: true});

    } else {
      let value = ('0' + hours).slice(-2) + ":" + ('0' + minutes).slice(-2) + ":" + ('0' + seconds).slice(-2);
      $(this.clockTarget).html(value)
      if (hours == 0 && minutes == 0 && seconds < 60 && !$(this.clockTarget).hasClass('text-danger')) {
        $(this.clockTarget).addClass('text-danger')
      }
    }
  }

  stopTimer() {
    if (this._timer) { return clearInterval(this._timer); }
  }
}
